import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// app specific

import loginReducer from "api/login/reducers";
import permissionsReducer from "api/permissions/reducers";

import { loginSagas } from "api/login/sagas";

import { permissionsSagas } from "api/permissions/sagas";

export const rootReducer = combineReducers({
  auth: auth.reducer,

  login: loginReducer,

  permissions: permissionsReducer,
});

export function* rootSaga() {
  yield all([auth.saga(), ...loginSagas, ...permissionsSagas]);
}
