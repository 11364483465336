import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { UbnSelectorDropdown } from "./UbnSelectorDropdown";
import ApiStorage from "../../ApiStorage";
import { useDispatch } from "react-redux";
import { loginActions } from "api/APIService";
import { shallowEqual, useSelector } from "react-redux";
import { isObjectEmpty } from "api/utils/utils";
import { useTranslation } from "react-i18next";

export default function TopBreadcrumbs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  //const [selectedUbn, setSelectedUbn] = useState(null);

  const { userProfile, myCompanies, selectedUbn } = useSelector(
    (state) => ({
      userProfile: state.login.userProfile,
      myCompanies: state.login.myCompanies,
      selectedUbn: state.login.selectedUbn,
    }),
    shallowEqual
  );

  /*   useEffect(() => {
    const getUser = async () => {
      try {
        const sessionToken = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(sessionToken);
        const { userProfile } = transformedData;

        setSelectedUbn(userProfile?.company);
        dispatch(loginActions.selectedUbn(userProfile?.company));
      } catch (e) {
        console.error(e, "<--- error getting session token");
      }
    };

    getUser();
  }, [dispatch, userProfile, myCompanies]); */

  useEffect(() => {
    //setSelectedUbn(myCompanies[0]);
    !isObjectEmpty(userProfile.company) &&
      myCompanies.length > 0 &&
      dispatch(loginActions.selectedUbn(userProfile?.company));
  }, [myCompanies, userProfile, dispatch]);

  const pressedCompany = async (company) => {
    const sessionToken = await ApiStorage.getSessionToken();
    const transformedData = JSON.parse(sessionToken);

    const { authToken, userProfile } = transformedData;
    userProfile.company = company;
    const jsonValue = JSON.stringify({
      authToken: authToken,
      userProfile: userProfile,
    });
    await ApiStorage.setSessionToken(jsonValue);

    dispatch(loginActions.selectedUbn(company));
    dispatch(loginActions.setUserProfile(userProfile));
    //setSelectedUbn(company);
    history.push("/");
  };

  return (
    <div className={classes.container}>
      {
        <Typography color="secondary" variant="h5">
          {t("companies")}
        </Typography>
      }
      {!isObjectEmpty(selectedUbn) && (
        <ChevronRightIcon className={classes.icon} />
      )}
      {!isObjectEmpty(selectedUbn) && (
        <UbnSelectorDropdown
          selectedUbn={selectedUbn}
          allUbns={myCompanies}
          pressedCompany={pressedCompany}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    display: "flex",
  },
  icon: {
    margin: "0 0.5rem",
  },
  ubnText: {
    cursor: "pointer",
  },
}));
