import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";

const AnalyticsPage = lazy(() => import("./pages/analytics/AnalyticsPage"));
const MyCompanyPage = lazy(() =>
  import("./pages/supply-chain-mapping/MyCompanyPage")
);
const MyRequests = lazy(() =>
  import("./pages/supply-chain-mapping/MyRequests")
);
const SupplierDetailsPage = lazy(() =>
  import("./pages/supply-chain-mapping/SupplierDetailsPage")
);
const RequestDetailsPage = lazy(() =>
  import("./pages/supply-chain-mapping/RequestDetailsPage")
);
const MySentRequests = lazy(() =>
  import("./pages/supply-chain-mapping/MySentRequests")
);

export default function BasePage() {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {<Redirect exact from="/" to="/supply-chain-mapping/company-info" />}
        <Route
          exact
          path="/supply-chain-mapping/company-info"
          component={MyCompanyPage}
        />
        <Route exact path="/analytics" component={AnalyticsPage} />
        <Route
          exact
          path="/supply-chain-mapping/received-requests"
          component={MyRequests}
        />
        <Route
          exact
          path="/supply-chain-mapping/sent-requests"
          component={MySentRequests}
        />
        <Route
          exact
          path="/supply-chain-mapping/company-info/supplier/:id"
          component={SupplierDetailsPage}
        />
        <Route
          exact
          path="/supply-chain-mapping/received-requests/from-customer/:id"
          component={RequestDetailsPage}
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
