/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import ApiStorage from "../../../../../app/ApiStorage";

export function AsideMenuList({ layoutProps }) {
  const { t } = useTranslation();
  const location = useLocation();

  const [profile, setProfile] = useState({});

  const getUserData = useCallback(async () => {
    try {
      const userData = await ApiStorage.getSessionToken();
      const transformedData = JSON.parse(userData);
      const { userProfile } = transformedData;
      setProfile(userProfile);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {profile.role === "Admin" && (
          <li
            className={`menu-item ${getMenuItemActive("/analytics", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/analytics">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">{t("analytics")}</span>
            </NavLink>
          </li>
        )}

        <li className="menu-section ">
          <h4 className="menu-text">{t("supply-chain-mapping")}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu menu-item-open`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          data-cy="prp"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Supply Chain Due Diligence</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">
                      Supply Chain Due Diligence
                    </span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/supply-chain-mapping/company-info"
                  )}`}
                  aria-haspopup="true"
                  data-cy="my-company"
                >
                  <NavLink
                    className="menu-link"
                    to="/supply-chain-mapping/company-info"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("my-company")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/supply-chain-mapping/sent-requests"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/supply-chain-mapping/sent-requests"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("sent-requests")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/supply-chain-mapping/received-requests"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/supply-chain-mapping/received-requests"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("received-requests")}</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
