/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import ApiStorage from "../../../../../app/ApiStorage";
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";
import APIService, { loginActions } from "api/APIService";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SnackBarComponent from "../../../../../app/pages/components/SnackBarComponent";
import { setMyCompanies } from "api/login/actions";
import { isObjectEmpty } from "api/utils/utils";
import ModalComponent from "../../../../../app/pages/components/ModalComponent";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import ListsWidget from "../../../../../app/pages/components/ListsWidget";

export function QuickUser({ messages }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({});
  const [companies, setCompanies] = useState([]);
  const [addButton, setAddButton] = useState(false);
  const [addCompanyResponse, setAddCompanyResponse] = useState({});
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("error");
  const [deleteCompany, setDeleteCompany] = useState({});

  const defaultProps = {
    options: industries,
    getOptionLabel: (option) => option,
  };

  const defaultCountryProps = {
    options: countries,
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await APIService.getOwnProfileSCM();

        const sessionToken = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(sessionToken);
        const { authToken, userProfile } = transformedData;
        // page reload
        let showCompanies = false;
        if (!isObjectEmpty(userProfile)) {
          showCompanies = userProfile.showAllCompanies;
        } else if (response.role === "Admin" || response.role === "BDO User") {
          showCompanies(true);
        }
        setShowAllCompanies(showCompanies);

        const jsonValue = JSON.stringify({
          authToken: authToken,
          userProfile: {
            ...response,
            company: userProfile.company,
            showAllCompanies: showCompanies,
          },
        });
        await ApiStorage.setSessionToken(jsonValue);
        setProfile(response);
        setContactFullName(response.data.fullName);
        setContactPhone(response.data.phoneNumber);
        dispatch(loginActions.setUserProfile(response));
      } catch (e) {
        console.error(e);
      }
    };
    getProfile();
  }, [dispatch]);

  const [allCompaniesResponse, setAllCompaniesResponse] = useState([]);
  useEffect(() => {
    const getAllCompanies = async () => {
      try {
        const res = await APIService.listAllCompaniesSCM();
        const response = res.filter((cmp) => {
          if (cmp.data) {
            return !cmp.data.isRemoved;
          } else return true;
        });
        setAllCompaniesResponse(response);
        dispatch(loginActions.setMyCompanies(response));
        const sessionToken = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(sessionToken);
        const { authToken, userProfile } = transformedData;
        userProfile.company = response[0];
        dispatch(loginActions.setUserProfile(userProfile));
        const jsonValue = JSON.stringify({
          authToken: authToken,
          userProfile: userProfile,
        });
        await ApiStorage.setSessionToken(jsonValue);
      } catch (e) {
        console.error(e);
      }
    };
    showAllCompanies && getAllCompanies();
  }, [showAllCompanies, dispatch]);

  useEffect(() => {
    const getMyCompanies = async () => {
      try {
        const res = await APIService.listMyCompaniesSCM();
        const response = res.filter((cmp) => {
          if (cmp.data) {
            return !cmp.data.isRemoved;
          } else return true;
        });
        const sessionToken = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(sessionToken);
        const { authToken, userProfile } = transformedData;
        userProfile.company = response[0];
        const jsonValue = JSON.stringify({
          authToken: authToken,
          userProfile: userProfile,
        });
        await ApiStorage.setSessionToken(jsonValue);
        dispatch(loginActions.setUserProfile(userProfile));
        setCompanies(response);
        dispatch(loginActions.setMyCompanies(response));
      } catch (e) {
        console.error(e);
      }
    };

    getMyCompanies();
  }, [addCompanyResponse, dispatch, deleteCompany]);

  useEffect(() => {
    const setMyCompanies = async () => {
      if (!showAllCompanies) {
        try {
          const res = await APIService.listMyCompaniesSCM();
          const response = res.filter((cmp) => {
            if (cmp.data) {
              return !cmp.data.isRemoved;
            } else return true;
          });
          dispatch(loginActions.setMyCompanies(response));
          const sessionToken = await ApiStorage.getSessionToken();
          const transformedData = JSON.parse(sessionToken);
          const { authToken, userProfile } = transformedData;
          userProfile.company = response.length > 0 ? response[0] : {};
          dispatch(loginActions.setUserProfile(userProfile));
          const jsonValue = JSON.stringify({
            authToken: authToken,
            userProfile: userProfile,
          });
          await ApiStorage.setSessionToken(jsonValue);
        } catch (e) {
          console.error(e);
        }
      }
    };

    !isObjectEmpty(profile) && setMyCompanies();
  }, [showAllCompanies, dispatch, profile]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await APIService.getCountriesList();
        setCountries(response);
      } catch (e) {
        console.error(e);
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getIndustries = async () => {
      try {
        const response = await APIService.getIndustriesList();
        setIndustries(response);
      } catch (e) {
        console.error(e);
      }
    };

    getIndustries();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const handleAddButton = () => {
    setAddButton(true);
  };

  const handleCancelAddButton = () => {
    setAddButton(false);
  };

  const [companyName, setCompanyName] = useState("");
  const handleSetCompanyName = (e) => {
    if (e.target.value.length > 0) {
      setNameError(false);
      setHelperNameText("");
    }
    setCompanyName(e.target.value);
  };

  const [companyDUNS, setCompanyDUNS] = useState("");
  const handleSetCompanyDUNS = (e) => {
    setCompanyDUNS(e.target.value);
  };

  const [companyWebsite, setCompanyWebsite] = useState("");
  const handleSetCompanyWebsite = (e) => {
    setCompanyWebsite(e.target.value);
  };
  const [companyIdentifier, setCompanyIdentifier] = useState("");
  const handleSetCompanyIdentifier = (e) => {
    if (e.target.value.length > 0) {
      setIdentifierError(false);
      setHelperText("");
    }
    setCompanyIdentifier(e.target.value);
  };

  const [companyCountry, setCompanyCountry] = useState("");
  const handleSetCompanyCountry = (value) => {
    if (value) {
      setCountryError(false);
      setHelperCountryText("");
    }
    setCompanyCountry(value);
  };

  const [companyNumber, setCompanyNumber] = useState("");
  const handleSetCompanyNumber = (e) => {
    setCompanyNumber(e.target.value);
  };

  const [companyIndustry, setCompanyIndustry] = useState("");
  const handleSetCompanyIndustry = (value) => {
    if (value) {
      setIndustryError(false);
      setHelperIndustryText("");
    }
    setCompanyIndustry(value);
  };

  const [helperText, setHelperText] = useState("");
  const [helperNameText, setHelperNameText] = useState("");
  const [helperCountryText, setHelperCountryText] = useState("");
  const [helperIndustryText, setHelperIndustryText] = useState("");
  const [identifierError, setIdentifierError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [industryError, setIndustryError] = useState(false);

  const handleAddCompany = async () => {
    try {
      let allEntered = true;
      if (!companyName) {
        setNameError(true);
        setHelperNameText(t("this-field-is-required"));
        allEntered = false;
      }
      if (!companyIdentifier) {
        setIdentifierError(true);
        setHelperText(t("this-field-is-required"));
        allEntered = false;
      }
      if (!companyCountry) {
        setCountryError(true);
        setHelperCountryText(t("this-field-is-required"));
        allEntered = false;
      }
      if (!companyIndustry) {
        setIndustryError(true);
        setHelperIndustryText(t("this-field-is-required"));
        allEntered = false;
      }

      if (!allEntered) {
        return;
      }
      const response = await APIService.addCompanySCM({
        company: {
          name: companyName,
          country: companyCountry,
          identifier: companyIdentifier,
          data: {
            industry: companyIndustry,
            duns: companyDUNS,
            website: companyWebsite,
            companyNumber: companyNumber,
          },
        },
      });
      setAddCompanyResponse(response);
      handleCancelAddButton();
      setMessage(t("successfully-added-company"));
      setVariant("success");
      setOpenSnackbar(true);
    } catch (e) {
      console.error(e.toString());
      setMessage(e.toString());
      setVariant("error");
      setOpenSnackbar(true);
      setAddCompanyResponse({});
    }
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const [companyId, setCompanyId] = useState("");

  const handleDelete = (e, id) => {
    setCompanyId(id);
    setOpenDelete(true);
  };

  const handleDeleteCompany = async (e) => {
    try {
      const response = await APIService.deleteCompanySCM({ id: companyId });
      setMessage(t("successfully-deleted-company"));
      setVariant("success");
      setOpenSnackbar(true);
      setDeleteCompany(response);
      handleCloseDelete();
      history.push("/supply-chain-mapping/company-info"); // we get fresh data from new company
    } catch (e) {
      console.error(e);
      setMessage(t("failed-to-delete-company-please-try-later-again"));
      setVariant("error");
      setOpenSnackbar(true);
      handleCloseDelete();
    }
  };

  const handleRemove = async (e, id) => {
    try {
      let company = companies.filter((cmp) => cmp._id === id)[0];
      company.data = { ...company.data, isRemoved: true };

      const response = await APIService.updateCompanySCM({
        id,
        company,
      });
      setMessage(t("successfully-deleted-company"));
      setVariant("success");
      setOpenSnackbar(true);
      setDeleteCompany(response);
      history.push("/supply-chain-mapping/company-info"); // we get fresh data from new company
    } catch (e) {
      console.error(e);
      setMessage(t("failed-to-delete-company-please-try-later-again"));
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const handleChange = async (event) => {
    setShowAllCompanies(event.target.checked);

    // set localstorage
    const sessionToken = await ApiStorage.getSessionToken();
    const transformedData = JSON.parse(sessionToken);
    const { authToken, userProfile } = transformedData;
    userProfile.showAllCompanies = event.target.checked;
    const jsonValue = JSON.stringify({
      authToken: authToken,
      userProfile: userProfile,
    });
    await ApiStorage.setSessionToken(jsonValue);
  };

  const [openCompanyInfoDialog, setOpenCompanyInfoDialog] = useState(false);
  const openGetCompanyInfoDialog = () => {
    setOpenCompanyInfoDialog(true);
  };

  const handleCloseCompanyInfoDialog = () => {
    setOpenCompanyInfoDialog(false);
    setErrorMessage("");
    setMyCompany({});
  };
  const [myCompany, setMyCompany] = useState({});
  const [companyIdDean, setCompanyIdDean] = useState("");
  const handleSetCompanyIdDean = (e) => {
    setCompanyIdDean(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const getCompanyById = async () => {
    setErrorMessage("");
    setMyCompany({});
    try {
      const response = await APIService.getCompanyByIdSCM({
        id: companyIdDean,
      });
      setMyCompany(response);
    } catch (e) {
      setErrorMessage(e.toString());
      console.error(e);
    }
  };

  const [openAllCompanies, setOpenAllCompanies] = useState(false);

  const openAllCompaniesDialog = () => {
    setOpenAllCompanies(true);
  };
  const handleCloseAllCompaniesDialog = () => {
    setOpenAllCompanies(false);
  };

  const handleDownloadXls = async (id) => {
    try {
      const response = await APIService.getBdoExcelReport({
        id,
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const url = window.URL.createObjectURL(
        new Blob([response], { type: fileType })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      //csvLink.current.link.click();
    } catch (e) {
      console.error(e);
    }
  };

  const allCompaniesBody = (
    <>
      <Grid container spacing={2}>
        {allCompaniesResponse.map((company) => {
          return (
            <Grid item xs={12} sm={6} md={3}>
              <a onClick={() => handleDownloadXls(company._id)}>
                <div
                  className="card card-custom gutter-b example"
                  style={{
                    boxShadow: "1px 2px #f6f6f8",
                    border: "1px solid #f6f6f8",
                  }}
                >
                  <div className="card-header" style={{ borderBottom: "none" }}>
                    <div className="card-title">
                      <h3 className="card-label">
                        {company.name
                          ? company.name
                          : company.proposedUpdates?.length > 0
                          ? "Proposed name - not yet verified"
                          : ""}
                      </h3>
                    </div>
                  </div>
                  <div className="card-body">{company.identifier}</div>
                </div>
              </a>
            </Grid>
          );
        })}
      </Grid>
    </>
  );

  const [openInvite, setOpenInvite] = useState(false);
  const openInviteDialog = () => {
    setOpenInvite(true);
  };
  const handleCloseInviteDialog = () => {
    setOpenInvite(false);
    setBdoError("");
    setBdoEmail("");
  };

  const [bdoEmail, setBdoEmail] = useState("");
  const [bdoError, setBdoError] = useState("");
  const inviteBdo = async () => {
    setBdoError("");
    try {
      const response = await APIService.addUserSCM({
        user: {
          email: bdoEmail,
          role: "BDO User",
        },
      });
      setOpenSnackbar(true);
      setMessage(t("successfully-invited-bdo-user"));
      setVariant("success");
      handleCloseInviteDialog();
    } catch (e) {
      if (e.toString().startsWith("Error: E11000")) {
        setBdoError(t("user-already-invited"));
      } else {
        setBdoError(e.toString());
      }
    }
  };

  const handleSetBdoEmail = (e) => {
    setBdoError("");
    setBdoEmail(e.target.value);
  };
  const inviteBody = (
    <>
      <div>
        <TextField
          id="outlined-basic-identifier"
          label={`${t("email")}`}
          value={bdoEmail}
          variant="outlined"
          fullWidth
          onChange={handleSetBdoEmail}
          style={{ marginBottom: "10px" }}
        />
        <button
          className="btn btn-primary btn-bold"
          onClick={inviteBdo}
          style={{ color: "#fff" }}
        >
          {t("invite")}
        </button>
      </div>
      {bdoError.length > 0 && (
        <div style={{ marginTop: "20px" }}>{bdoError}</div>
      )}
    </>
  );

  const deanBody = (
    <>
      <div>
        <TextField
          id="outlined-basic-identifier"
          label={`${t("company-id")}`}
          value={companyIdDean}
          variant="outlined"
          fullWidth
          onChange={handleSetCompanyIdDean}
          style={{ marginBottom: "10px" }}
        />
        <button
          className="btn btn-primary btn-bold"
          onClick={getCompanyById}
          style={{ color: "#fff" }}
        >
          {t("get-info")}
        </button>
      </div>
      {errorMessage.length > 0 && (
        <div style={{ marginTop: "20px" }}>{errorMessage}</div>
      )}
      {!isObjectEmpty(myCompany) && (
        <>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6}>
              <ListsWidget
                title={t("name")}
                content={
                  myCompany.name
                    ? myCompany.name
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed name - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"name-key"}
              />
              <ListsWidget
                title={t("industry")}
                content={
                  myCompany?.data?.industry
                    ? myCompany?.data?.industry
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed industry - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"industry-key"}
              />
              <ListsWidget
                title={t("country")}
                content={
                  myCompany.country
                    ? myCompany.country
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed country - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"country-key"}
              />
              <ListsWidget
                title={t("registry-ID")}
                content={myCompany.identifier}
                color="secondary"
                showIcon={false}
                customKey={"registry-key"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListsWidget
                title={t("website")}
                content={
                  myCompany?.data?.website
                    ? myCompany?.data?.website
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed website - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"website-key"}
              />
              <ListsWidget
                title={t("duns")}
                content={
                  myCompany?.data?.duns
                    ? myCompany?.data?.duns
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed DUNS - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"duns-key"}
              />
              <ListsWidget
                title={t("company-number")}
                content={
                  myCompany?.data?.companyNumber
                    ? myCompany?.data?.companyNumber
                    : myCompany.proposedUpdates?.length > 0
                    ? "Proposed company number - not yet verified"
                    : ""
                }
                color="secondary"
                showIcon={false}
                customKey={"company-number-key"}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );

  const handleChangeCompany = async (company) => {
    const sessionToken = await ApiStorage.getSessionToken();
    const transformedData = JSON.parse(sessionToken);

    const { authToken, userProfile } = transformedData;
    userProfile.company = company;
    const jsonValue = JSON.stringify({
      authToken: authToken,
      userProfile: userProfile,
    });
    await ApiStorage.setSessionToken(jsonValue);

    dispatch(loginActions.selectedUbn(company));
    dispatch(loginActions.setUserProfile(userProfile));
    //setSelectedUbn(company);
  };

  const [updateUserResponse, setUpdateUserResponse] = useState({});
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await APIService.getOwnProfileSCM();
        const sessionToken = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(sessionToken);
        const { authToken, userProfile } = transformedData;
        const jsonValue = JSON.stringify({
          authToken: authToken,
          userProfile: {
            ...response,
            company: userProfile.company,
            showAllCompanies:
              response.role === "Admin" || response.role === "BDO User"
                ? true
                : false,
          },
        });

        await ApiStorage.setSessionToken(jsonValue);
        dispatch(
          loginActions.setUserProfile({
            ...response,
            company: userProfile.company,
          })
        );
      } catch (e) {
        console.error(e);
      }
    };
    !isObjectEmpty(updateUserResponse) && getProfile();
  }, [dispatch, updateUserResponse]);

  const [contactFullName, setContactFullName] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const handleSetContactFullName = (e) => {
    setContactFullName(e.target.value);
  };

  const handleSetContactPhone = (e) => {
    setContactPhone(e.target.value);
  };

  const handleUpdateUserProfile = async () => {
    let data = {};
    if (contactFullName) {
      data.fullName = contactFullName;
    }
    if (contactPhone) {
      data.phoneNumber = contactPhone;
    }

    try {
      const response = await APIService.updateUserSCM({
        id: profile._id,
        user: {
          data,
        },
      });
      setUpdateUserResponse(response);
      setMessage("Updated contact info");
      setVariant("success");
      setOpenSnackbar(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          {messages.userProfile}
          {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
        </h3>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </span>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/svg/avatars/001-boy.svg"
                )})`,
              }}
            />

            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {profile.role || ""}
            </a>

            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {profile.email}
                  </span>
                </span>
              </a>
            </div>

            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-primary btn-bold"
              onClick={logoutClick}
              style={{ color: "#fff" }}
            >
              {messages.signOut}
            </button>
          </div>
        </div>
        <div className="separator separator-dashed mt-2 mb-4" />
        <div className="d-flex align-items-center mt-5"></div>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <h5>{t("contact-info")}</h5>
        </div>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <TextField
              id="outlined-basic-name"
              label={`${t("full-name")}`}
              value={contactFullName}
              fullWidth
              onChange={handleSetContactFullName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic-phone"
              label={`${t("phone-number")}`}
              value={contactPhone}
              fullWidth
              onChange={handleSetContactPhone}
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpdateUserProfile}
          >
            {t("save")}
          </Button>
        </div>
        {(profile.role === "Admin" || profile.role === "BDO User") && (
          <>
            <div className="separator separator-dashed mt-2 mb-4" />
            <div className="d-flex align-items-center mt-5"></div>{" "}
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2">
              <p className=" m-0">
                {t("show-all-companies")}
                {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
              </p>
              <Switch
                checked={showAllCompanies}
                onChange={handleChange}
                name="checkedA"
                color="primary"
              />
            </div>
            {profile.role === "BDO User" && (
              <>
                <div className="d-flex align-items-center mt-5"></div>
                <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2">
                  <p className=" m-0">
                    {t("companies-overview")}
                    {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
                  </p>
                  <button
                    className="btn btn-link"
                    onClick={openAllCompaniesDialog}
                  >
                    {t("view")}
                  </button>
                </div>
              </>
            )}
          </>
        )}
        {profile.role === "Admin" && (
          <>
            <div className="separator separator-dashed mt-2 mb-4" />
            <div className="d-flex align-items-center mt-5"></div>{" "}
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2">
              <p className=" m-0">
                {t("invite-bdo-user")}
                {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
              </p>
              <button className="btn btn-link" onClick={openInviteDialog}>
                {t("invite")}
              </button>
            </div>
          </>
        )}
        {profile.role === "BDO User" && (
          <>
            <div className="separator separator-dashed mt-2 mb-4" />
            <div className="d-flex align-items-center mt-5"></div>{" "}
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2 mt-10">
              <p className=" m-0">
                {t("de-anonimize-company")}
                {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
              </p>
              <button
                className="btn btn-primary btn-bold"
                onClick={openGetCompanyInfoDialog}
                style={{ color: "#fff" }}
              >
                {t("get-info")}
              </button>
            </div>
          </>
        )}
        {/*         <div>
          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Chart-bar1.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>
          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/Selected-file.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>
          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a>
        </div> */}
        <div className="separator separator-dashed my-7"></div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              marginBottom: "15px",
            }}
          >
            <h5 className="mb-5">
              {addButton ? t("add-new-company") : t("my-companies")}
            </h5>
            {addButton ? (
              <button
                className="btn btn-secondary "
                onClick={handleCancelAddButton}
                style={{ color: "#fff" }}
              >
                {t("cancel")}
              </button>
            ) : (
              <>
                {profile.role === "Admin" && (
                  <button
                    className="btn btn-primary btn-bold"
                    onClick={handleAddButton}
                    style={{ color: "#fff" }}
                  >
                    {t("add")}
                  </button>
                )}
              </>
            )}
          </div>
          {profile.role === "Admin" && addButton ? (
            <>
              <TextField
                id="outlined-basic-name"
                label={`${t("name")} *`}
                value={companyName}
                variant="outlined"
                fullWidth
                error={nameError}
                helperText={helperNameText}
                onChange={handleSetCompanyName}
                style={{ marginBottom: "10px" }}
              />

              <Autocomplete
                {...defaultProps}
                onChange={(event, value) => handleSetCompanyIndustry(value)}
                value={companyIndustry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("industry")}
                    fullWidth
                    error={industryError}
                    helperText={helperIndustryText}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
              />
              <Autocomplete
                {...defaultCountryProps}
                onChange={(event, value) => handleSetCompanyCountry(value)}
                value={companyCountry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("country")}
                    fullWidth
                    error={countryError}
                    helperText={helperCountryText}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
              />

              <TextField
                id="outlined-basic-identifier"
                label={`${t("registry-id")} *`}
                value={companyIdentifier}
                variant="outlined"
                fullWidth
                error={identifierError}
                helperText={helperText}
                onChange={handleSetCompanyIdentifier}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                id="outlined-basic-identifier-type"
                label={`${t("website")}`}
                value={companyWebsite}
                variant="outlined"
                fullWidth
                onChange={handleSetCompanyWebsite}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                id="outlined-basic-data"
                label={`${t("company-number")}`}
                value={companyNumber}
                variant="outlined"
                fullWidth
                onChange={handleSetCompanyNumber}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                id="outlined-basic-data"
                label={`${t("duns")}`}
                value={companyDUNS}
                variant="outlined"
                fullWidth
                onChange={handleSetCompanyDUNS}
                style={{ marginBottom: "10px" }}
              />
              <button
                className="btn btn-primary btn-bold"
                onClick={handleAddCompany}
                style={{ color: "#fff" }}
              >
                {t("save")}
              </button>
            </>
          ) : (
            <>
              {companies.length > 0 ? (
                <>
                  {companies.map((company) => {
                    return (
                      <div
                        className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b"
                        key={company.identifier}
                      >
                        <a
                          href="#"
                          onClick={(e) => handleChangeCompany(company)}
                          className="d-flex flex-column flex-grow-1 mr-2"
                        >
                          <span className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                            {company.name}
                          </span>
                          <span className="text-muted font-size-sm">
                            {company.identifier}
                          </span>
                          <span className="text-muted font-size-sm">
                            {company.country}
                          </span>
                        </a>
                        {(profile.role === "Admin" ||
                          profile.role === "User") && (
                          <span className="font-weight-bolder text-warning py-1 font-size-lg">
                            <Tooltip title="Remove company - this will remove the company from your companies list, but you will still be able to see it requests">
                              <IconButton
                                aria-label="Remove"
                                onClick={(e) => handleRemove(e, company._id)}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                        {profile.role === "Admin" && (
                          <span className="font-weight-bolder text-warning py-1 font-size-lg">
                            <Tooltip title={t("delete")}>
                              <IconButton
                                style={{ color: "#d1583c" }}
                                aria-label={t("delete")}
                                onClick={(e) => handleDelete(e, company._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center bg-light-info rounded p-10 gutter-b">
                    <span className="svg-icon svg-icon-info mr-5">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Warning-2.svg"
                        )}
                        className="svg-icon svg-icon-lg"
                      ></SVG>
                    </span>

                    <div className="d-flex flex-column flex-grow-1 mr-2">
                      <span className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">
                        {t("no-companies-yet")}
                      </span>
                      <span className="text-muted font-size-sm">
                        {t("add-new-company")}
                      </span>
                    </div>

                    <span className="font-weight-bolder text-info py-1 font-size-lg"></span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={message}
      />
      <ModalComponent
        open={openDelete}
        handleClose={handleCloseDelete}
        dialogTitle={t("permanently-delete-company")}
        dialogContent={t(
          "are-you-sure-you-want-to-delete-this-company-you will-loose-all-company-data"
        )}
        maxWidth="sm"
        dialogActions={
          <>
            <Button
              onClick={handleCloseDelete}
              className="cancel-button"
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleDeleteCompany}
              variant="contained"
              className="yes-button"
              color="primary"
            >
              {t("yes")}
            </Button>
          </>
        }
      />
      <ModalComponent
        open={openCompanyInfoDialog}
        handleClose={handleCloseCompanyInfoDialog}
        dialogTitle={t("de-anonimize-company")}
        dialogContent={deanBody}
        maxWidth="sm"
        dialogActions={
          <>
            <Button
              onClick={handleCloseCompanyInfoDialog}
              className="cancel-button"
              color="secondary"
            >
              {t("close")}
            </Button>
          </>
        }
      />
      <ModalComponent
        open={openAllCompanies}
        handleClose={handleCloseAllCompaniesDialog}
        dialogTitle={t("companies-overview")}
        dialogContent={allCompaniesBody}
        maxWidth="md"
        dialogActions={
          <>
            <Button
              onClick={handleCloseAllCompaniesDialog}
              className="cancel-button"
              color="secondary"
            >
              {t("close")}
            </Button>
          </>
        }
      />
      <ModalComponent
        open={openInvite}
        handleClose={handleCloseInviteDialog}
        dialogTitle={t("invite-bdo-user")}
        dialogContent={inviteBody}
        maxWidth="sm"
        dialogActions={
          <>
            <Button
              onClick={handleCloseInviteDialog}
              className="cancel-button"
              color="secondary"
            >
              {t("close")}
            </Button>
          </>
        }
      />
    </div>
  );
}
