/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ApiStorage from "./ApiStorage";
import ChangeUrl from "./pages/ChangeUrl";
import AuthRequest from "./modules/Auth/pages/AuthRequests";
import AuthCompany from "./modules/Auth/pages/AuthCompany";
import AuthCode from "./modules/Auth/pages/AuthCode";

export function Routes(props) {
  // needed for component update
  // eslint-disable-next-line no-unused-vars
  const { loginRes, logoutFlag, services } = useSelector(
    (state) => ({
      loginRes: state.login.loginRes,
      logoutFlag: state.login.logoutFlag,
      services: state.permissions.services,
    }),
    shallowEqual
  );

  // Check that PRP is activated
  // TODO: Refactor this
  // let PRPActivated = true;
  // const subs = JSON.parse(ApiStorage.getSessionToken())?.userProfile
  //   ?.subscriptions;
  // if (subs && subs.find((s) => s.type === "UBN" && s.status === "ACTIVE")) {
  //   PRPActivated = true;
  // }

  return (
    <Switch>
      <Route exact path="/auth/change-url" component={ChangeUrl} />
      <Route exact path="/auth/login-secret" component={AuthCode} />
      <Route exact path="/auth/login" component={AuthPage} />
      <Route exact path="/auth-requests" component={AuthRequest} />
      <Route exact path="/auth-company" component={AuthCompany} />

      {(ApiStorage.getSessionToken() === null ||
        JSON.parse(ApiStorage.getSessionToken()).authToken === undefined) &&
      window.location.pathname !== "/auth/login-secret" &&
      window.location.pathname !== "/auth-requests" &&
      window.location.pathname !== "/auth-company" ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {(ApiStorage.getSessionToken() === null ||
        JSON.parse(ApiStorage.getSessionToken()).authToken === undefined) &&
      window.location.pathname !== "/auth/login-secret" &&
      window.location.pathname !== "/auth-requests" &&
      window.location.pathname !== "/auth-company" ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
