import React from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";

const ListsWidget = ({
  title,
  content,
  titleHref = "",
  isClickable = false,
  isIconClickable = false,
  color = "primary",
  icon,
  href,
  showIcon = true,
  margin = "mb-4",
  handleClick = () => {},
  button = false,
  handleDelete = () => {},
  customKey = "key",
  buttonProposed = false,
  handleAccept = () => {},
  handleReject = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className={`d-flex align-items-center ${margin}`} key={customKey}>
      {showIcon && (
        <div className={`symbol symbol-40 symbol-light-${color} mr-5`}>
          {isIconClickable ? (
            <button
              onClick={handleClick}
              className="symbol-label"
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "#fff",
                padding: "0",
              }}
            >
              <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                {icon}
              </span>
            </button>
          ) : (
            <span
              className="symbol-label"
              style={{ backgroundColor: "#F0F4F6" }}
            >
              <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                  {icon}
                </span>
              </span>
            </span>
          )}
        </div>
      )}
      <div className="d-flex flex-column font-weight-bold">
        {titleHref.length > 0 ? (
          <Link className="text-muted" to={titleHref}>
            {title}
          </Link>
        ) : (
          <span className="text-muted" style={{ textAlign: "left" }}>
            {title}
          </span>
        )}
        {isClickable ? (
          <a
            onClick={handleClick}
            className="text-dark text-hover-primary mb-1 font-size-lg"
            style={{ wordBreak: "break-all" }}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content}
          </a>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              onClick={handleClick}
              className={`text-dark text-hover-${color} mb-1 font-size-lg`}
              style={{ wordBreak: "break-all" }}
            >
              {content}
            </span>
            {button && (
              <Tooltip title={t("remove-user-from-company")}>
                <IconButton aria-label={t("delete")} onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {buttonProposed && (
              <div style={{ display: "inline-flex" }}>
                <Tooltip title={t("reject-user")}>
                  <IconButton aria-label={t("reject")} onClick={handleReject}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("reject-user")}>
                  <IconButton aria-label={t("accept")} onClick={handleAccept}>
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListsWidget;
