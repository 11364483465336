/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function UbnSelectorDropdown({ selectedUbn, pressedCompany, allUbns }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dropdown drop="down" align="right">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">{t("select-company")}</Tooltip>
          }
        >
          <div>
            <Typography className={classes.text} variant="h6">
              {selectedUbn.name ? selectedUbn.name : selectedUbn.identifier}
            </Typography>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-left dropdown-menu-anim dropdown-menu-top-unround">
        <ul
          className="navi navi-hover py-4"
          style={{ overflowY: "scroll", maxHeight: "500px" }}
        >
          {allUbns.map((u, i) => {
            return (
              <div
                onClick={() => pressedCompany(u)}
                className={classes.row}
                key={`${u.name}${i}`}
              >
                <p style={{ margin: 0, padding: 0 }}>
                  {u.name ? u.name : u.identifier}
                </p>
              </div>
            );
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "pointer",
    padding: "1rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  text: {
    cursor: "pointer",
    textDecorationLine: "underline",
  },
}));
