import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiService from "api/APIService";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        ApiService["loginUserSCM"]({
          email: values.email,
        })
          .then((result) => {
            disableLoading();
            //dispatch(loginActions.receiveLogin(result));
            //const queryParams = getQueryParams();
            //if (queryParams.redirectPath) {
            //props.history.push(queryParams.redirectPath);
            //} else {
            // props.history.push({
            //   pathname: "/auth/login-secret",
            //   state: { email: values.email },
            // });
            //}
            setShowMessage(true);
          })
          .catch((error) => {
            if (error.message === "Invalid 2FA token!") {
              disableLoading();
              setSubmitting(false);
            } else {
              disableLoading();
              setSubmitting(false);
              setStatus("Invalid login");
            }
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login</h3>
        {!ApiService.showRegisterPage && <h6>only for registered customers</h6>}
        {/* <p className="text-muted font-weight-bold">
          Enter your <strong>UNISOT ID</strong> email and password
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      {showMessage ? (
        <>
          <h6 style={{ textAlign: "center" }}>
            Check your email for login link
          </h6>
        </>
      ) : (
        <>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              <div className="mb-10 alert alert-custom alert-dismissible">
                <div className="alert-text">Use your email to continue.</div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                id="email"
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* <div className="form-group fv-plugins-icon-container">
          <input
            id="password"
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div> */}
            {/* {tokenInput && (
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Token"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="token"
              {...formik.getFieldProps("token")}
            />
            {formik.touched.token && formik.errors.token ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.token}</div>
              </div>
            ) : null}
          </div>
        )} */}
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              {/*  <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot password
          </Link> */}
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span style={{ color: "#fff" }}>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </>
      )}
    </div>
  );
}

export default Login;
