import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import APIService, { loginActions } from "api/APIService";
import ApiStorage from "../../../ApiStorage";

function AuthRequest(props) {
  const dispatch = useDispatch();

  const Loading = (loading) => {
    return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
  };

  React.useEffect(() => {
    const setTokenAndRedirect = async () => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        requestId: queryParams.get("request-id"),
        jwt: queryParams.get("jwt"),
        jwtExp: queryParams.get("jwt_expiration"),
      };

      fetch(new URL(`https://testsdd.unisot.id/api/users/me`), {
        method: "GET",
        headers: {
          authorization: `Bearer ${params.jwt}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(`Request rejected with status ${res.status}`);
          }
        })
        .then((result) => {
          dispatch(loginActions.setUserProfile(result));
          const jsonParams = {
            authToken: params.jwt,
            userProfile: { ...result, showAllCompanies: false },
          };

          const jsonValue = JSON.stringify(jsonParams);
          return ApiStorage.setSessionToken(jsonValue);
        })
        .then(() => {
          props.history.push(
            `supply-chain-mapping/received-requests/from-customer/${params.requestId}`
          );
        });
    };
    setTokenAndRedirect();
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
}

export default AuthRequest;
