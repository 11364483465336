/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from "react-i18next";
import moment from "moment";
import APIService from "api/APIService";
import { formatDateTime } from "api/utils/utils";
import ListsWidget from "../../../../../app/pages/components/ListsWidget";
import { isObjectEmpty } from "api/utils/utils";
import ApiStorage from "../../../../../app/ApiStorage";
import { Link } from "react-router-dom";

export function UserNotificationsDropdown() {
  const { t } = useTranslation();

  const { selectedUbn } = useSelector(
    (state) => ({
      selectedUbn: state.login.selectedUbn,
    }),
    shallowEqual
  );
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(userData);
        const { userProfile } = transformedData;
        setProfile(userProfile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserData();
  }, []);

  const [notifications, setNotifications] = useState(0);
  // useEffect(() => {
  //   const getRelations = async () => {
  //     try {
  //       let allRelations;
  //       let myRelations;
  //       if (profile.showAllCompanies) {
  //         allRelations = await APIService.listAllRelationsSCM();
  //       } else {
  //         myRelations = await APIService.listMyRelationsSCM();
  //       }
  //       let relations;
  //       if (profile.showAllCompanies && allRelations.length > 0) {
  //         relations = allRelations.filter((rel) => {
  //           return rel.supplier === selectedUbn?._id;
  //         });
  //       } else if (!isObjectEmpty(myRelations)) {
  //         relations = myRelations.recipients.filter((rel) => {
  //           return rel.supplier === selectedUbn?._id;
  //         });
  //       }
  //       setNotifications(relations.length);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };

  //   getRelations();
  // }, [profile, selectedUbn]);

  const [myRelations, setMyRelations] = useState({});
  const [allRelations, setAllRelations] = useState([]);
  useEffect(() => {
    const getRelations = async () => {
      try {
        let response;
        if (profile.showAllCompanies) {
          response = await APIService.listAllRelationsSCM();
          setAllRelations(response);
        } else {
          response = await APIService.listMyRelationsSCM();
          setMyRelations(response);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getRelations();
  }, [selectedUbn, profile]);

  const [myCustomers, setMyCustomers] = useState([]);
  useEffect(() => {
    if (profile.showAllCompanies && allRelations.length > 0) {
      setMyCustomers(
        allRelations.filter((rel) => {
          return rel.supplier === selectedUbn?._id;
        })
      );
    } else if (!isObjectEmpty(myRelations)) {
      setMyCustomers(
        myRelations.recipients.filter((rel) => {
          return rel.supplier === selectedUbn?._id;
        })
      );
    }
  }, [myRelations, allRelations, profile]);

  const [myCustomersDetails, setMyCustomersDetails] = useState([]);

  useEffect(() => {
    const getCustomersById = async () => {
      const relationIdsList = myCustomers.map((sup) => {
        return sup._id;
      });
      try {
        const response = await APIService.listFromRelationsSCM({
          relations: relationIdsList,
        });
        setMyCustomersDetails(response);
      } catch (e) {
        console.error(e);
      }
    };
    myCustomers.length > 0 && getCustomersById();
    myCustomers.length === 0 && setNotifications(0);
  }, [myCustomers]);

  useEffect(() => {
    const enrichCustomers = () => {
      const customersList = myCustomers.map((cust) => {
        const customerDetails = myCustomersDetails.filter(
          (details) => details._id === cust.supplyRecipient
        );
        cust.customerDetails = customerDetails[0];
        return cust;
      });
      setNotifications(
        customersList
          .filter((cust) => cust.customerDetails !== undefined)
          .filter((sup) => sup.response === "NoResponse").length
      );
    };

    myCustomers.length > 0 && enrichCustomers();
  }, [myCustomersDetails, myCustomers]);

  const handleOnClick = () => {};

  return (
    <div className="topbar-item">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="user-notification-tooltip">
            <div>
              {notifications > 0
                ? `You have ${notifications} pending requests`
                : t("no-new-notifications")}
            </div>
          </Tooltip>
        }
      >
        <Link to="/supply-chain-mapping/received-requests">
          <button
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
            onClick={handleOnClick}
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <Badge badgeContent={notifications} color="primary">
                <NotificationsNoneIcon color="primary" />
              </Badge>
            </span>

            {notifications > 0 && (
              <>
                <span className="pulse-ring"></span>
                <span className="pulse-ring"></span>
              </>
            )}
          </button>
        </Link>
      </OverlayTrigger>
    </div>
  );
}
