import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import APIService, { loginActions } from "api/APIService";
import ApiStorage from "../../../ApiStorage";

function AuthCompany(props) {
  const dispatch = useDispatch();

  const Loading = (loading) => {
    return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
  };

  React.useEffect(() => {
    const setTokenAndRedirect = async () => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        companyId: queryParams.get("company-id"),
        jwt: queryParams.get("jwt"),
        jwtExp: queryParams.get("jwt_expiration"),
      };

      fetch(new URL(`https://testsdd.unisot.id/api/users/me`), {
        method: "GET",
        headers: {
          authorization: `Bearer ${params.jwt}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(`Request rejected with status ${res.status}`);
          }
        })
        .then((result) => {
          dispatch(loginActions.setUserProfile(result));
          const jsonParams = {
            authToken: params.jwt,
            userProfile: result,
          };

          const jsonValue = JSON.stringify(jsonParams);
          return ApiStorage.setSessionToken(jsonValue);
        })
        // .then(() => {
        //   return fetch(
        //     new URL(
        //       `https://testsdd.unisot.id/api/company/id/${params.companyId}`
        //     ),
        //     {
        //       method: "GET",
        //       headers: {
        //         authorization: `Bearer ${params.jwt}`,
        //       },
        //     }
        //   );
        // })
        // .then((res) => {
        //   if (res.ok) {
        //     return res.json();
        //   } else {
        //     props.history.push(
        //       `supply-chain-mapping/company-info?no-access=true`
        //     );
        //     throw Error(`Request rejected with status ${res.status}`);
        //   }
        // })
        // .then((result) => {
        //   //dispatch(loginActions.setUserProfile(result));
        //   const sessionToken = ApiStorage.getSessionToken();
        //   const transformedData = JSON.parse(sessionToken);
        //   const { authToken, userProfile } = transformedData;
        //   userProfile.company = result;

        //   const jsonValue = JSON.stringify({
        //     authToken: authToken,
        //     userProfile: userProfile,
        //   });
        //   return ApiStorage.setSessionToken(jsonValue);
        // })
        .then(() => {
          props.history.push(
            `supply-chain-mapping/company-info?company-id=${params.companyId}`
          );
        });
      //.catch((error) => console.error(error));
    };
    setTokenAndRedirect();
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
}

export default AuthCompany;
