import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import APIService, { loginActions } from "api/APIService";
import ApiStorage from "../../../ApiStorage";

function AuthCode(props) {
  const dispatch = useDispatch();

  const Loading = (loading) => {
    return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
  };

  React.useEffect(() => {
    const loginUser = async () => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        email: queryParams.get("email"),
        otp: queryParams.get("otp"),
      };

      fetch(
        new URL(
          `https://testsdd.unisot.id/api/auth/login/${params.email}/${params.otp}`
        ),
        {
          method: "POST",
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(`Request rejected with status ${res.status}`);
          }
        })
        .then((result) => {
          dispatch(loginActions.receiveLogin(result));
          return result;
        })
        .then((token) => {
          fetch(new URL(`https://testsdd.unisot.id/api/users/me`), {
            method: "GET",
            headers: {
              authorization: `Bearer ${token.token}`,
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw Error(`Request rejected with status ${res.status}`);
              }
            })
            .then((result) => {
              dispatch(loginActions.setUserProfile(result));
              const jsonParams = {
                authToken: token.token,
                userProfile: result,
              };

              const jsonValue = JSON.stringify(jsonParams);
              return ApiStorage.setSessionToken(jsonValue);
            })
            .then(() => {
              props.history.push("/");
            });
        })
        .catch((e) => {
          props.history.push("/auth/login");
        });
    };
    loginUser();
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
}

export default AuthCode;
